var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container CollegeManagement" },
    [
      _vm.course != 1
        ? _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-row",
                { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                [_c("Head", { attrs: { name: _vm.title } })],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleTabClick },
                  model: {
                    value: _vm.approver_type,
                    callback: function ($$v) {
                      _vm.approver_type = $$v
                    },
                    expression: "approver_type",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "主管驳回", name: "1" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "财务驳回", name: "2" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-header",
                { staticStyle: { height: "auto" } },
                [
                  _c(
                    "el-row",
                    { staticClass: "btn", attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 6, lg: 8, xl: 5 } },
                        [
                          _vm._v(
                            "\n                    学员来源：\n                    "
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                size: "small",
                                filterable: "",
                                placeholder: "请搜索花名",
                                clearable: "",
                              },
                              model: {
                                value: _vm.source_client_id,
                                callback: function ($$v) {
                                  _vm.source_client_id = $$v
                                },
                                expression: "source_client_id",
                              },
                            },
                            _vm._l(_vm.flower_Array, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.flower_name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 11, md: 9, lg: 8, xl: 5 } },
                        [
                          _vm._v(
                            "\n                    是否支持分期付款：\n                    "
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.orderTypes,
                                callback: function ($$v) {
                                  _vm.orderTypes = $$v
                                },
                                expression: "orderTypes",
                              },
                            },
                            _vm._l(_vm.typeoptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 11, md: 9, lg: 10, xl: 6 } },
                        [
                          _vm._v(
                            "\n                    金额区间：\n                    "
                          ),
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              size: "small",
                              type: "text",
                              clearable: "",
                            },
                            on: {
                              input: function ($event) {
                                _vm.smoney = _vm.smoney.replace(
                                  /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                                  "$1"
                                )
                              },
                            },
                            model: {
                              value: _vm.smoney,
                              callback: function ($$v) {
                                _vm.smoney = $$v
                              },
                              expression: "smoney",
                            },
                          }),
                          _vm._v("-\n                    "),
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              size: "small",
                              type: "text",
                              clearable: "",
                            },
                            on: {
                              input: function ($event) {
                                _vm.emoney = _vm.emoney.replace(
                                  /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                                  "$1"
                                )
                              },
                            },
                            model: {
                              value: _vm.emoney,
                              callback: function ($$v) {
                                _vm.emoney = $$v
                              },
                              expression: "emoney",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 8, lg: 9, xl: 6 } },
                        [
                          _vm._v(
                            "\n                    成交人所属部门：\n                    "
                          ),
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.bumenoptions,
                              size: "small",
                              clearable: "",
                              "collapse-tags": "",
                              props: {
                                value: "id",
                                label: "name",
                                multiple: true,
                              },
                            },
                            on: { change: _vm.orderDepartmentChange },
                            model: {
                              value: _vm.orderDepartment,
                              callback: function ($$v) {
                                _vm.orderDepartment = $$v
                              },
                              expression: "orderDepartment",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 8, lg: 10, xl: 6 } },
                        [
                          _vm._v(
                            "\n                    客户创建人所属部门：\n                    "
                          ),
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.bumenoptions,
                              size: "small",
                              clearable: "",
                              "collapse-tags": "",
                              props: {
                                value: "id",
                                label: "name",
                                multiple: true,
                              },
                            },
                            on: { change: _vm.createDepartmentChange },
                            model: {
                              value: _vm.createDepartment,
                              callback: function ($$v) {
                                _vm.createDepartment = $$v
                              },
                              expression: "createDepartment",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 14, lg: 12, xl: 7 } },
                        [
                          _vm.approver_type == 1
                            ? _c("span", [_vm._v("审核时间：")])
                            : _c("span", [_vm._v("驳回时间：")]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { width: "360px !important" },
                            attrs: {
                              size: "small",
                              "value-format": "timestamp",
                              type: "datetimerange",
                              "range-separator": "-",
                              "default-time": ["00:00:00", "23:59:59"],
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.auditTime,
                              callback: function ($$v) {
                                _vm.auditTime = $$v
                              },
                              expression: "auditTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 3 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "审核人搜索",
                              clearable: "",
                              size: "small",
                            },
                            model: {
                              value: _vm.approver,
                              callback: function ($$v) {
                                _vm.approver = $$v
                              },
                              expression: "approver",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请搜索电话、名称",
                              clearable: "",
                              size: "small",
                            },
                            model: {
                              value: _vm.ClassSearch,
                              callback: function ($$v) {
                                _vm.ClassSearch = $$v
                              },
                              expression: "ClassSearch",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 12, sm: 12, md: 4, lg: 4, xl: 2 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.searchOrder },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "padding-left": "0",
                            "padding-right": "0",
                            "margin-top": "2px",
                            "padding-top": "20px",
                          },
                          attrs: { span: 2 },
                        },
                        [
                          _c("Display-settings", {
                            staticClass: "ml10 filter-item",
                            staticStyle: {
                              float: "right",
                              "margin-top": "0px",
                            },
                            attrs: {
                              Views: _vm.views,
                              "display-arr": _vm.displaySettingsWhole,
                              "display-value": _vm.displaySettings,
                            },
                            on: {
                              "update:displayValue": function ($event) {
                                _vm.displaySettings = $event
                              },
                              "update:display-value": function ($event) {
                                _vm.displaySettings = $event
                              },
                              TableVisible: function (val) {
                                return _vm.getList(
                                  this$1.query.page,
                                  this$1.query.pagesize,
                                  val,
                                  1
                                )
                              },
                              dialogFork: function (val) {
                                return _vm.getList(
                                  this$1.query.page,
                                  this$1.query.pagesize,
                                  val,
                                  1
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.feeshow,
                                expression: "feeshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("缴费用途："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  size: "small",
                                },
                                on: {
                                  change: _vm.handleFee,
                                  clear: _vm.clearFee,
                                },
                                model: {
                                  value: _vm.payPurpose,
                                  callback: function ($$v) {
                                    _vm.payPurpose = $$v
                                  },
                                  expression: "payPurpose",
                                },
                              },
                              _vm._l(_vm.feeoptions, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("缴费用途")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.feeDateshow,
                                expression: "feeDateshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("缴费日期："),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "360px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.handleFeeDate },
                              model: {
                                value: _vm.feeDate,
                                callback: function ($$v) {
                                  _vm.feeDate = $$v
                                },
                                expression: "feeDate",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("缴费日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.typeShow,
                                expression: "typeShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("客户种类："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  size: "small",
                                },
                                on: {
                                  change: _vm.handleFee,
                                  clear: _vm.clearFee,
                                },
                                model: {
                                  value: _vm.types,
                                  callback: function ($$v) {
                                    _vm.types = $$v
                                  },
                                  expression: "types",
                                },
                              },
                              _vm._l(_vm.typeArr, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("客户种类")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.modeShow,
                                expression: "modeShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("支付方式："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  size: "small",
                                },
                                on: {
                                  change: _vm.handleFee,
                                  clear: _vm.clearFee,
                                },
                                model: {
                                  value: _vm.mode,
                                  callback: function ($$v) {
                                    _vm.mode = $$v
                                  },
                                  expression: "mode",
                                },
                              },
                              _vm._l(_vm.modeArr, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("支付方式")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.collegesShow,
                                expression: "collegesShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("所选院校："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  size: "small",
                                  filterable: "",
                                },
                                on: {
                                  change: _vm.handleFee,
                                  clear: _vm.clearFee,
                                  focus: function ($event) {
                                    return _vm.collegeSer(1)
                                  },
                                },
                                model: {
                                  value: _vm.colleges,
                                  callback: function ($$v) {
                                    _vm.colleges = $$v
                                  },
                                  expression: "colleges",
                                },
                              },
                              _vm._l(_vm.collegesArr, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("所选院校")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.majorShow,
                                expression: "majorShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("所选专业："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  size: "small",
                                  filterable: "",
                                },
                                on: {
                                  change: _vm.handleFee,
                                  clear: _vm.clearFee,
                                  focus: function ($event) {
                                    return _vm.collegeSer(2)
                                  },
                                },
                                model: {
                                  value: _vm.major,
                                  callback: function ($$v) {
                                    _vm.major = $$v
                                  },
                                  expression: "major",
                                },
                              },
                              _vm._l(_vm.majorArr, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("所选专业")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.classShow,
                                expression: "classShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("所选班级："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  size: "small",
                                  filterable: "",
                                },
                                on: {
                                  change: _vm.handleFee,
                                  clear: _vm.clearFee,
                                  focus: function ($event) {
                                    return _vm.collegeSer(3)
                                  },
                                },
                                model: {
                                  value: _vm.classE,
                                  callback: function ($$v) {
                                    _vm.classE = $$v
                                  },
                                  expression: "classE",
                                },
                              },
                              _vm._l(_vm.classArr, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("所选班级")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.SchoolShow,
                                expression: "SchoolShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("开学日期："),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "360px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.SchoolChange },
                              model: {
                                value: _vm.schoolTime,
                                callback: function ($$v) {
                                  _vm.schoolTime = $$v
                                },
                                expression: "schoolTime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("开学日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.creatershow,
                                expression: "creatershow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("客户创建人："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "medium",
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.handleCreater },
                                model: {
                                  value: _vm.Studentleve_teacherid,
                                  callback: function ($$v) {
                                    _vm.Studentleve_teacherid = $$v
                                  },
                                  expression: "Studentleve_teacherid",
                                },
                              },
                              _vm._l(
                                _vm.Studentleve_teachername,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.realname,
                                      value: item.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("客户创建人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ordershow,
                                expression: "ordershow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("客户成交人："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "medium",
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.handleOrder },
                                model: {
                                  value: _vm.Studentleve_teacherid2,
                                  callback: function ($$v) {
                                    _vm.Studentleve_teacherid2 = $$v
                                  },
                                  expression: "Studentleve_teacherid2",
                                },
                              },
                              _vm._l(
                                _vm.Studentleve_teachername,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.realname,
                                      value: item.id,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("客户成交人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.submitShow,
                                expression: "submitShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("客户创建时间："),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "360px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.establishTimeChange },
                              model: {
                                value: _vm.establishTime,
                                callback: function ($$v) {
                                  _vm.establishTime = $$v
                                },
                                expression: "establishTime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("客户创建时间")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.submitshow,
                                expression: "submitshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("订单提交时间："),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "360px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.submitOrderTime },
                              model: {
                                value: _vm.submitTime,
                                callback: function ($$v) {
                                  _vm.submitTime = $$v
                                },
                                expression: "submitTime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("订单提交时间")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-main",
                [
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          border: "",
                          id: "customerTable",
                          "header-cell-style": {
                            background: "#F9F9F9",
                            color: "#222222",
                          },
                        },
                        on: { "sort-change": _vm.sortEs },
                      },
                      [
                        _vm.displaySettings.includes("客户名称")
                          ? _c("el-table-column", {
                              key: 1,
                              attrs: {
                                fixed: "",
                                label: "客户名称",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { color: "#1890ff" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goTodetails(
                                                  scope.row,
                                                  scope
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.cname)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1568735955
                              ),
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("联系电话")
                          ? _c("el-table-column", {
                              key: 2,
                              attrs: {
                                fixed: "",
                                label: "联系电话",
                                prop: "mobile",
                                align: "center",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("学员来源")
                          ? _c("el-table-column", {
                              key: 3,
                              attrs: {
                                label: "学员来源",
                                prop: "source_name",
                                align: "center",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("缴费日期")
                          ? _c(
                              "el-table-column",
                              {
                                key: 4,
                                attrs: {
                                  label: "缴费日期",
                                  prop: "pay_time",
                                  align: "center",
                                  filters: [],
                                  width: "120px",
                                  sortable: "custom",
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("缴费日期")
                                        },
                                      },
                                    },
                                    [_vm._v("缴费日期")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("缴费用途")
                          ? _c(
                              "el-table-column",
                              {
                                key: 6,
                                attrs: {
                                  label: "缴费用途",
                                  prop: "pay_purpose",
                                  align: "center",
                                  formatter: _vm.formatPurpose,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return _vm._l(
                                          scope.row.pay_amount_detail,
                                          function (item, index) {
                                            return _c("div", { key: index }, [
                                              item.type == 1
                                                ? _c("div", [
                                                    _c("span", [
                                                      _vm._v("报名费"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        ":" +
                                                          _vm._s(item.amount)
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.type == 2
                                                ? _c("div", [
                                                    _c("span", [
                                                      _vm._v("定金"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        ":" +
                                                          _vm._s(item.amount)
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.type == 3
                                                ? _c("div", [
                                                    _c("span", [
                                                      _vm._v("学费"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        ":" +
                                                          _vm._s(item.amount)
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.type == 4
                                                ? _c("div", [
                                                    _c("span", [
                                                      _vm._v("资料费"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        ":" +
                                                          _vm._s(item.amount)
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.type == 5
                                                ? _c("div", [
                                                    _c("span", [
                                                      _vm._v("语言培训费"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        ":" +
                                                          _vm._s(item.amount)
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.type == 6
                                                ? _c("div", [
                                                    _c("span", [
                                                      _vm._v("普通留学申请费"),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        ":" +
                                                          _vm._s(item.amount)
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          }
                                        )
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1698136753
                                ),
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("缴费用途")
                                        },
                                      },
                                    },
                                    [_vm._v("缴费用途")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("学费总额")
                          ? _c("el-table-column", {
                              key: 23,
                              attrs: {
                                label: "学费总额",
                                prop: "total_tuition_fees",
                                align: "center",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("客户种类") &&
                        _vm.approver_type == 1
                          ? _c(
                              "el-table-column",
                              {
                                key: 7,
                                attrs: {
                                  label: "客户种类",
                                  prop: "leibie",
                                  align: "center",
                                  formatter: _vm.purposePurpose,
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("客户种类")
                                        },
                                      },
                                    },
                                    [_vm._v("客户种类")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("支付方式") &&
                        _vm.approver_type == 1
                          ? _c(
                              "el-table-column",
                              {
                                key: 8,
                                attrs: {
                                  label: "支付方式",
                                  prop: "pay_type",
                                  align: "center",
                                  formatter: _vm.typeName,
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("支付方式")
                                        },
                                      },
                                    },
                                    [_vm._v("支付方式")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("是否支持分期付款")
                          ? _c("el-table-column", {
                              key: 9,
                              attrs: {
                                label: "是否支持分期付款",
                                prop: "order_type",
                                align: "center",
                                width: "150",
                                formatter: _vm.orderType,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("收款分期期数")
                          ? _c("el-table-column", {
                              key: 10,
                              attrs: {
                                label: "收款分期期数",
                                prop: "periodization",
                                align: "center",
                                width: "120px",
                                formatter: _vm.formatPeriodization,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("收款批次")
                          ? _c("el-table-column", {
                              key: 11,
                              attrs: {
                                label: "收款批次",
                                prop: "periodization_set",
                                align: "center",
                                formatter: _vm.formatSet,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("收款金额")
                          ? _c("el-table-column", {
                              key: 12,
                              attrs: {
                                label: "收款金额",
                                prop: "money",
                                align: "center",
                                formatter: _vm.formatMoney,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("本次收款金额")
                          ? _c("el-table-column", {
                              key: 13,
                              attrs: {
                                label: "本次收款金额",
                                prop: "money",
                                align: "center",
                                width: "140px",
                                formatter: _vm.formatMoneyNow,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("客户创建人")
                          ? _c(
                              "el-table-column",
                              {
                                key: 14,
                                attrs: {
                                  label: "客户创建人",
                                  prop: "customer_create_name",
                                  align: "center",
                                  width: "140",
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("客户创建人")
                                        },
                                      },
                                    },
                                    [_vm._v("客户创建人")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("客户创建时间")
                          ? _c(
                              "el-table-column",
                              {
                                key: 15,
                                attrs: {
                                  label: "客户创建时间",
                                  prop: "client_create_time",
                                  align: "center",
                                  sortable: "custom",
                                  width: "150",
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("客户创建时间")
                                        },
                                      },
                                    },
                                    [_vm._v("客户创建时间")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("订单提交时间")
                          ? _c(
                              "el-table-column",
                              {
                                key: 16,
                                attrs: {
                                  label: "订单提交时间",
                                  prop: "create_time",
                                  align: "center",
                                  sortable: "custom",
                                  width: "150",
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("订单提交时间")
                                        },
                                      },
                                    },
                                    [_vm._v("订单提交时间")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("客户成交人")
                          ? _c(
                              "el-table-column",
                              {
                                key: 17,
                                attrs: {
                                  label: "客户成交人",
                                  prop: "order_create_name",
                                  align: "center",
                                  width: "140",
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("客户成交人")
                                        },
                                      },
                                    },
                                    [_vm._v("客户成交人")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("审核详情")
                          ? _c("el-table-column", {
                              key: 18,
                              attrs: {
                                label: "审核详情",
                                align: "center",
                                width: "140",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: { color: "#1890ff" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openAuditDialog(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("审核详情")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1370148304
                              ),
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("所选院校")
                          ? _c(
                              "el-table-column",
                              {
                                key: 19,
                                attrs: {
                                  label: "所选院校",
                                  prop: "school_name",
                                  align: "center",
                                  width: "140",
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("所选院校")
                                        },
                                      },
                                    },
                                    [_vm._v("所选院校")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("所选专业")
                          ? _c(
                              "el-table-column",
                              {
                                key: 20,
                                attrs: {
                                  label: "所选专业",
                                  prop: "major_name",
                                  align: "center",
                                  width: "140",
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("所选专业")
                                        },
                                      },
                                    },
                                    [_vm._v("所选专业")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("所选班级")
                          ? _c(
                              "el-table-column",
                              {
                                key: 21,
                                attrs: {
                                  label: "所选班级",
                                  prop: "class_name",
                                  align: "center",
                                  width: "140",
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("所选班级")
                                        },
                                      },
                                    },
                                    [_vm._v("所选班级")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.displaySettings.includes("开学日期")
                          ? _c(
                              "el-table-column",
                              {
                                key: 22,
                                attrs: {
                                  label: "开学日期",
                                  prop: "term_begin_time",
                                  align: "center",
                                  width: "140",
                                },
                              },
                              [
                                _c("template", { slot: "header" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        color: "#1890ff",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Activeshow("开学日期")
                                        },
                                      },
                                    },
                                    [_vm._v("开学日期")]
                                  ),
                                ]),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        ref: "tableLeft",
                        attrs: { id: "tableLeft" },
                        on: {
                          scroll: function ($event) {
                            return _vm.exterHandleScroll()
                          },
                        },
                      },
                      [
                        _c("div", {
                          staticStyle: { height: "10px" },
                          attrs: { id: "bodyLeft" },
                        }),
                      ]
                    ),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  "current-page": _vm.query.page,
                  totalPage: _vm.query.total,
                  pageSize: _vm.query.pagesize,
                },
                on: {
                  handleSizeChange: _vm.handleSizeChange,
                  handleCurrentChange: _vm.handleCurrentChange,
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "boxImg",
              staticStyle: { width: "100%", padding: "20px" },
            },
            [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
            1
          ),
      _vm._v(" "),
      _c("auditDialog", {
        ref: "auditDialog",
        attrs: { form: _vm.auditDetailData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }